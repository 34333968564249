<template>
  <div>
    <v-dialog
      v-if="showDetailedNotification"
      v-model="showDetailedNotification"
      :width="isMobile ? '100%' : '50%'"
    >
      <v-card>
        <v-card-title class="ma-0 pa-0 mb-4">
          <v-toolbar class="primary pl-2" dark>
            <v-toolbar-title class="">
              <h3>
                {{ selectedNotification.subject }}
              </h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined class="">
              <v-icon @click="exitDetailedNotification" class=""
                >mdi-close</v-icon
              >
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <v-row align="center" justify="center" no-gutters>
            <v-col class="mx-2 d-flex text-body-1" cols="12">
              Gesendet von:
              {{ selectedNotification.fromUsername }} </v-col
            ><v-col class="mx-2 d-flex text-body-1" cols="12">
              Gesendet am:
              {{ selectedNotification.a_dat }}
            </v-col>
            <v-divider class="black--text mt-2 mb-2"></v-divider>
            <v-col class="mx-2 d-flex black--text text-body-1" cols="12">
              {{ selectedNotification.message }}
            </v-col>
          </v-row>

          <v-col class="mt-5 mb-n5 d-flex justify-end">
            <v-btn
              @click="
                changeReadStatus(selectedNotification.nId),
                  exitDetailedNotification()
              "
              outlined
            >
              Als Ungelesen markieren
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <SiteHeaderComponent header="Benachrichtigungen" />
    <v-container class="d-flex justify-center mb-16">
      <v-card width="100%" class="align-center mb-16">
        <v-card-title>
          <v-text-field
            filled
            v-model="search"
            append-icon="mdi-magnify"
            label="Nachricht suchen ..."
            single-line
            outlined
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="this.$store.state.notifications.notifications"
            class="mt-2 elevation-1 datatable-row-pointer"
            sort-by="a_dat"
            :sort-desc="true"
            @click:row="openNotification"
            mobile-breakpoint="740"
          >
            <template v-slot:item.read="{ item }">
              <v-chip
                small
                :color="item.read ? 'grey lighten-3' : 'green'"
                :text-color="item.read ? 'black' : 'white'"
              >
                <v-col class="d-flex justify-center" v-if="item.read"
                  >Gelesen</v-col
                >
                <v-col class="d-flex justify-center" v-else>Neu</v-col>
              </v-chip>
            </template>
            <template v-slot:item.changeRead="{ item }">
              <v-btn
                class="mx-1"
                fab
                small
                dark
                @click.stop="changeReadStatus(item.nId)"
              >
                <v-icon v-if="item.read == false">mdi-email-check</v-icon>
                <v-icon v-if="item.read == true">mdi-email-remove</v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data>
              Keine Benachrichtigungen gefunden.
            </template>
            <template v-slot:no-results>
              Keine Benachrichtigungen gefunden.
            </template>
            <template v-slot:[`footer.prepend`]>
              <v-btn
                class="ml-2 mb-2"
                small
                depressed
                @click="$vuetify.goTo(target, scrollOptions)"
              >
                Nach oben
                <v-icon> mdi-arrow-up-thin </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
export default {
  components: {
    SiteHeaderComponent
  },
  data: () => ({
    showOnlyUnreadNotifications: false,
    showDetailedNotification: false,
    target: 0,
    scrollOptions: {
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic'
    },
    search: '',
    headers: [
      { text: 'Status', value: 'read' },
      { text: 'Gesendet am', value: 'a_dat' },
      {
        text: 'Betreff',
        value: 'subject'
      },
      { text: 'Von', value: 'fromUsername', align: 'start' },
      {
        text: 'Nachricht',
        value: 'message',
        align: 'start'
      },
      {
        text: 'Status ändern',
        value: 'changeRead',
        sortable: false,
        align: 'center'
      }
    ],
    selectedNotification: null,
    isMobile: false
  }),
  mounted() {
    this.$store.dispatch('GET_ALL_NOTIFICATIONS')

    if (typeof this.$route != undefined) {
      if ('query' in this.$route && this.$route.query.nId != null) {
        this.openDetailedNotification(this.$route.query.nId)
      }
    }

    //Breakpoint
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    //Öffnet einen Dialog die eine Übersicht einer einzigen Notification anzeigt (Bei Klick auf Notification)
    openDetailedNotification(nId) {
      if (this.$store.state.notifications.notifications.length == 0) {
        throw "Trying to open a Notification that doesn't exist or is not loaded"
      } else {
        this.selectedNotification = this.$store.state.notifications.notifications.find(
          notification => notification.nId == nId
        )

        this.showDetailedNotification = true
        console.log('CHANGE_READ')
        if (this.selectedNotification.read == false) {
          this.$store.dispatch(
            'CHANGE_READ_STATUS_NOTIFICATION',
            this.selectedNotification.nId
          )
        }
        console.log(this.$store.state.notifications.notifications)
      }
    },
    //Öffnet Notification Übersicht
    openNotification(notification) {
      this.$router.push({
        query: { nId: notification.nId }
      })
    },
    exitDetailedNotification() {
      this.$router.push({
        name: 'Notification'
      })
      this.showDetailedNotification = false
    },
    changeReadStatus(nId) {
      this.$store.dispatch('CHANGE_READ_STATUS_NOTIFICATION', nId)
    }
  },
  watch: {
    $route(newRoute) {
      if ('query' in newRoute && newRoute.query.nId != null) {
        this.openDetailedNotification(newRoute.query.nId)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-chip.v-size--small {
  width: 70px;
}
</style>
